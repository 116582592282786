import { Box } from "@mui/system";
import { Autocomplete, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { ISaving } from "../../../interface/saving";

const SavingLabelField = ({
  saving,
  formik,
}: {
  saving?: ISaving,
  formik: any
}) => {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const [fixedLabel, setFixedLabel] = useState<boolean>(false);

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
          {!saving ? (
            <>
              <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : !fixedLabel ? "bold" : ""}}>Epargne courante</Typography>
                <Switch 
                    {...label} 
                    onClick={() => {
                      formik.setFieldValue("label", "");
                      setFixedLabel(!fixedLabel)
                    }} 
                    checked={fixedLabel} 
                /> 
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : fixedLabel ? "bold" : ""}}>Epargne libre</Typography>
              </Box>
              {fixedLabel && (<TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Epargne Libre"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />)}
              {!fixedLabel && (<Autocomplete
                key="label"
                id="fixedLabel"
                options={["PEE - PERCO", "PEE - PERCO - Cotisations Sociales", "PEE - PERCO - Charges Sociales Prévisionnelles", "PEE - PERCO - Frais Annuels"]}
                value={formik.values.label || null}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, value) => formik.setFieldValue("label", value || "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    name="label"
                    margin="normal"
                    label="Epargnes Courantes"
                  />
                )}
              />)}
            </>
            ) : (
              <TextField
                margin="normal"
                required
                type="text"
                id="customLabel"
                label="Libellé de l'épargne"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                autoFocus
              />
            )}
        </Box>
    </>
  )
}

export default SavingLabelField;