import { Box, Button, Checkbox, Dialog, DialogTitle, IconButton, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react";
import TurnoverData from "./TurnoverData";
import CloseIcon from "@mui/icons-material/Close";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import AddUpdateTurnover from "../AddUpdate/AddUpdateTurnover";
import { addUpdateTurnover } from "../../../tools/forecast/addUpdateTurnover";
import { useParams } from "react-router";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { showSnackbar } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

const Turnover = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    let dispatch = useDispatch();

    const [agency, setAgency] = useState<string | null>("All");

    const headers = ["Tout", "Forfait", "Hébergement", "Régie", "TMA/TME", "TRA"];
    const agencies = [
        { label: "Toutes les agences", value: "All" },
        { label: "Agence Nord", value: "Nord" },
        { label: "Agence Sud", value: "Sud" }
    ];
    const [activeTypes, setActiveTypes] = useState<string[]>(["Forfait", "Hébergement", "Régie", "TMA/TME", "TRA"]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    const [selectedTurnover, setSelectedTurnover] = useState({
        "Régie": [],
        "Forfait": [],
        "Hébergement": [],
        "TMA/TME": [],
        "TRA": []
    });

    const [turnoverId, setTurnoverId] = useState<string>("");

    const confirmDelete = async () => {
        try {
            for (const [key, value] of Object.entries(selectedTurnover)) {
                for (const item of value) {
                    try {
                        const response = await axios.delete(
                            `${urlAPI}/forecast/turnover/${item}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );
                    } catch (error) {
                        console.error(`Erreur lors de la suppression de : ${item}`, error);
                    }
                }
            }
            setShowDeleteDialog(false);
            setSelectedTurnover({
                "Régie": [],
                "Forfait": [],
                "Hébergement": [],
                "TMA/TME": [],
                "TRA": []
            })
            dispatch(showSnackbar("Prestation(s) supprimée(s) avec succès.", "success"));
        } catch (error: any) {

            if (error.response) {
                console.error("Erreur API :", error.response.data);
                console.error("Statut :", error.response.status);
            } else if (error.request) {
                console.error("Erreur de requête :", error.request);
            } else {
                console.error("Erreur :", error.message);
            }
            dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
        }
    }

    const params = useParams();

    const monthList = getForecastMonthList(forecastDates);

    const handleAddUpdate = () => {
        if (Object.values(selectedTurnover).reduce((count, arr) => count + arr.length, 0) == 1) {
            Object.values(selectedTurnover).map((selectedId) => {
                if (selectedId.length > 0) {
                    setTurnoverId(selectedId[0]);
                }
            })
        }
        else {
            setTurnoverId("");
        }
        setOpenModal(true);
    }

    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>Chiffre d'Affaire</Typography>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Select
                    labelId="agency"
                    id="agency"
                    value={agency}
                    label="Agence"
                    onChange={(event) => { setAgency(event.target.value) }}
                    sx={{ width: "200px", height: "50px" }}
                >
                    {agencies.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    labelId="turnoverType"
                    id="turnoverType"
                    value={activeTypes}
                    label="Type de prestation"
                    onChange={(event: any) => { setActiveTypes(event.target.value) }}
                    multiple
                    sx={{ width: "300px", height: "50px" }}
                    renderValue={(selected) => selected.join(", ")}
                >
                    {headers.map((opt, index) => (
                        <MenuItem key={index} value={opt} sx={{ backgroundColor: activeTypes.includes(opt) ? "#FE9900" : "white" }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", }}>
                                <Box>{opt}</Box> <Checkbox checked={activeTypes.includes(opt)} />
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
                <Box sx={{ display: "flex", width: "450px", justifyContent: "right", columnGap: "50px" }}>
                    {Object.values(selectedTurnover).reduce((count, arr) => count + arr.length, 0) > 0 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "red" }} onClick={() => setShowDeleteDialog(true)}>- Supprimer</Button>
                    )}
                    {Object.values(selectedTurnover).reduce((count, arr) => count + arr.length, 0) < 2 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleAddUpdate}>
                            {Object.values(selectedTurnover).reduce((count, arr) => count + arr.length, 0) == 1 ? 'Modifier la prestation' : "+ Ajouter une prestation"}
                        </Button>
                    )}
                </Box>
            </Box>

            {
                activeTypes.map((type, index) => {
                    return (
                        <TurnoverData
                            key={type + index}
                            forecastVersion={forecastVersion}
                            forecastDates={forecastDates}
                            type={type}
                            agency={agency}
                            setSelectedTurnover={setSelectedTurnover}
                            openModal={openModal}
                            showDeleteDialog={showDeleteDialog}
                        />
                    )
                })
            }
            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
                onKeyDown={(event) => {

                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>{turnoverId ? "Modifier une prestation" : "Ajouter une prestation"}</DialogTitle>
                    <IconButton onClick={() => setShowConfirmDialog(true)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateTurnover
                    turnoverId={turnoverId}
                    handleSubmit={addUpdateTurnover}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenModal}
                    monthList={monthList}
                />
            </Dialog>
            <AlerteDialog
                open={showConfirmDialog}
                handleClose={() => setShowConfirmDialog(false)}
                deleteProject={() => { setShowConfirmDialog(false); setOpenModal(false) }}
                title="Confirmer l'abandon"
                contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                color="error"
            />
            <AlerteDialog
                open={showDeleteDialog}
                handleClose={() => setShowDeleteDialog(false)}
                deleteProject={confirmDelete}
                title="Confirmer la suppression"
                contentText="Êtes-vous sûr de vouloir supprimer les prestations sélectionnées de façon définitive ?"
                color="error"
            />
        </>
    )
}

export default Turnover;