import { TextField } from "@mui/material";
import { Box } from "@mui/system";

const TurnoverFixedFields = ({
  formik,
} : {
  formik: any
}) => {
  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
            <TextField
                margin="normal"
                required
                type="text"
                id="type"
                label="Type de prestation"
                name="type"
                autoComplete="type"
                value={formik.values.type}
                disabled
                autoFocus
            />
            <TextField
                margin="normal"
                required
                type="text"
                id="agency"
                label="Agence"
                name="agency"
                autoComplete="agency"
                value={formik.values.agency ? formik.values.agency : "Non précisée"}
                disabled
                autoFocus
            />
            <TextField
                margin="normal"
                required
                type="text"
                id="client"
                label="Client"
                name="client"
                autoComplete="client"
                value={formik.values.client}
                disabled
                autoFocus
            />
            {formik.values.type === "Régie" ? (
                formik.values.collab ? (
                    <TextField
                        margin="normal"
                        required
                        type="text"
                        id="collab"
                        label="Collaborateur"
                        name="collab"
                        autoComplete="collab"
                        value={formik.values.collab}
                        disabled
                        autoFocus
                    />
                ) : (
                    <TextField
                        margin="normal"
                        required
                        type="text"
                        id="fictifCollab"
                        label="Collaborateur"
                        name="fictifCollab"
                        autoComplete="fictifCollab"
                        value={formik.values.fictifCollab}
                        disabled
                        autoFocus
                    />
                )
            ) : (
                <TextField
                    margin="normal"
                    required
                    type="text"
                    id="project"
                    label="Projet"
                    name="project"
                    autoComplete="project"
                    value={formik.values.project}
                    disabled
                    autoFocus
                />
            )}
        </Box>
    </>
  )
}

export default TurnoverFixedFields;