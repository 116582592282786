import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { ICompany, ICompanyFormValues } from "../../../interface/company";
import CompanyLabelField from "./CompanyLabelField";
import CompanyMonthFields from "./CompanyMonthFields";

const validationSchema = yup.object({
  label: yup.string().required("Le libellé est obligatoire"),
});

const AddUpdateCompany = ({
  companyId,
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList,
  setShowConfirmDialog
} : {
  companyId?: string;
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  monthList: { name: string, field: string }[],
  setShowConfirmDialog : Function
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [company, setCompany] = useState<ICompany>();

  const getCompany = async () => {
    if (!token || !companyId) {
      return;
    }
    try {
      const syncResponse = await axios.get(`${urlAPI}/forecast/company/${companyId}`, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setCompany(syncResponse.data.message);
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    getCompany();
  }, []); 

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
        label: company ? company.label : "",
        months : company ? company.months
        : monthList.reduce((acc: any, item: any) => {
          acc[item.field] = 0;
          return acc;
        }, {}),
    },
    validationSchema: validationSchema,
    onSubmit: (values: ICompanyFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError, companyId);
    },
    enableReinitialize: true,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        <CompanyLabelField company={company} formik={formik} />
        <CompanyMonthFields formik={formik} monthList={monthList} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => {setShowConfirmDialog(true)}}>Annuler</Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
      </Box>
    </Box>
  )
}

export default AddUpdateCompany;