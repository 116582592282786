import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useParams } from "react-router";
import { ITNS } from "../../../interface/tns";
import getTNSData from "../../../tools/forecast/getTNSData";

const TNSData = ({ 
    forecastVersion, 
    forecastDates, 
    selectedTNS,
    setSelectedTNS,
    openModal,
    showDeleteDialog,
    setEditId,
    setOpenModal
} : { 
    forecastVersion: number | undefined, 
    forecastDates: string , 
    selectedTNS : string[],
    setSelectedTNS: Function,
    openModal: boolean,
    showDeleteDialog : boolean,
    setEditId : Function,
    setOpenModal : Function
}) => {
    let dispatch = useDispatch();
    
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();
    
    const [tnsData, setTnsData] = useState<ITNS[]>([]);
    const monthList = getForecastMonthList(forecastDates);

    useEffect(() => {
        getTNSData(params.id, forecastVersion, setTnsData, token, dispatch, setError);
    }, [forecastVersion, openModal, showDeleteDialog, selectedTNS]);
    
    const columnsToDisplay = {
        infos : [ 
            {name : "Libellé", field : "label"},
        ],
        months : monthList
    }
    
    const columnsInfos : GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
                field: column.field,
                headerName: column.name,
                filterable: true,
                minWidth : 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
                headerClassName: "custom-header",
                renderCell: (params) => (
                    <div style={{ whiteSpace: 'normal' }}>
                        {params.value?.split('\n').map((line: any, index: any) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                ),
        }
    })

    const columnsMonth : GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
                field: column.field,
                headerName: column.name,
                minWidth : 160,
                headerClassName: "custom-header",
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    const turnoverRows: GridRowsProp = tnsData.map((tns: ITNS) => {
        const turnoverRow : any = {
            id: tns._id,
            label: `\n${tns.label}\n`,
        };       

        const monthExpenses = Object.fromEntries(
            Object.entries(tns.months).map(([key, value]) => [key, `\n${value} €\n`])
        );

        return { ...turnoverRow, ...monthExpenses };
    });

    const totalByMonth = monthList.reduce((acc: any, item: any) => {
        acc[item.field] = tnsData.reduce((sum, tns) => sum + (tns.months[item.field] || 0), 0);
        return acc;
    }, {});

    const totalRow = {
        id: "total", // Une ID unique pour cette ligne
        label: `\nTOTAL\n`,
        ...Object.fromEntries(
            Object.entries(totalByMonth).map(([key, value]) => [key, `\n${value} €\n`])
        )
    };

    const rows: GridRowsProp = tnsData.length > 0 ? [...turnoverRows, totalRow] : [...turnoverRows];

    return (
        <>
            <DataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => 'auto'}
                onCellClick={(row) => {if(row.field !== "__check__" && row.id !== 'total') {setEditId(row.id); setOpenModal(true);}}}
                sx={{
                    '& .MuiDataGrid-columnHeader .sticky-column' : {
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        backgroundColor: "white",
                    },
                    '& .sticky-column': {
                        position: 'sticky',
                        left: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                    },
                    height: "100%"
                }}
                checkboxSelection
                rowSelectionModel={selectedTNS}
                onRowSelectionModelChange={(selectionModel) => {setSelectedTNS(selectionModel)}}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                
            />
        </>
    )
}

export default TNSData;