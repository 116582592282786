import {Typography, AppBar, Toolbar, Select, MenuItem, Button, Box, Checkbox } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IForecast } from "../../../interface/forecast";
import { fetchPostProtect } from "../../../fetchs/post";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { sessionEnd, showSnackbar } from "../../../redux/actions";
import { deleteOne } from "../../../fetchs/delete";

const ForecastHeader = ({
    forecast,
    activeTab,
    forecastDates,
    forecastTabs,
    setActiveTab,
    version,
    setVersion,
    official,
    navigate,
    dispatch,
    setError,
    getForecast
} : {
    forecast : IForecast | undefined,
    activeTab : string[],
    forecastDates : string,
    forecastTabs : {title: string, component: JSX.Element}[],
    setActiveTab : Function,
    version : number,
    setVersion : Function,
    official: boolean,
    navigate : Function,
    dispatch: Function,
    setError: Function,
    getForecast: Function
}) => {
    const token = useSelector((state: RootState) => {
        return state.user.token;
    });

    const duplicateVersion = async () => {
      if(token && forecast){
        const body = JSON.stringify({
          id : forecast._id,
          oldVersion : version,
          newVersion : forecast.versions[forecast.versions.length-1].number + 1
        });
      
        if (token) {
            await fetchPostProtect(token, "/forecast/duplicateVersion", body)
            .then((response) => {
                if (response.status === 200) {
                  dispatch(showSnackbar("Version dupliquée avec succès !", "success"));
                  getForecast();
                } else if (response.status === 401) {
                    dispatch(sessionEnd());
                } else {
                    response.json().then((result) => setError(result.message));
                }
            })
            .catch((err) => setError(err));
        }
      }
    }

    const deleteVersion = async () => {
      if (token && forecast) {
        await deleteOne(token, "/forecast/version/", forecast._id.toString()+"/"+version).then((res) => {
          if (res.status === 200) {
            dispatch(showSnackbar("Version supprimée avec succès !", "success"));
            getForecast();
          } else if (res.status === 401) {
            dispatch(sessionEnd());
          } else {
            res.json().then((err) => {
              setError(err.error);
              dispatch(showSnackbar("Erreur lors de la suppression du projet : " + err.error, "error"));
            });
          }
        });
      }
    }

    const changeOfficialVersion = async () => {
        const body = JSON.stringify({
            id : forecast?._id,
            version : version
        });
    
        if (token) {
            await fetchPostProtect(token, "/forecast/officialVersion", body)
            .then((response) => {
                if (response.status === 200) {
                  dispatch(showSnackbar("Version officielle modifiée avec succès !", "success"));
                  getForecast();
                } else if (response.status === 401) {
                    dispatch(sessionEnd());
                } else {
                    response.json().then((result) => setError(result.message));
                }
            })
            .catch((err) => setError(err));
        }
    }

    const handleSectionChange = (event : any) => {
      setActiveTab(event.target.value);
    }

    return (
        <AppBar position="static">
            <Toolbar sx={{display: "flex", justifyContent:"space-between"}}>
              <Box sx={{display : "flex"}}>
                <ArrowBackIcon
                  className="backToProjectArrow"
                  sx={{ mr: 3 }}
                  onClick={() => {navigate("/prévisionnel")}}
                />
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", sm: "block" },
                  }}
                >
                  {forecast?.name ? forecast.name + " - " : ""} {forecastDates}
              </Typography>
              </Box>
              <Select
                  labelId="activeTab"
                  id="activeTab"
                  value={activeTab}
                  label="Famille de dépense"
                  onChange={handleSectionChange}
                  multiple
                  sx={{ width: "30%", height: "50px", backgroundColor : "white"}}
                  renderValue={(selected) => selected.join(", ")}
              >
                  {
                    forecastTabs.map((option, index) => {
                      return(<MenuItem key={index} value={option.title} sx={{backgroundColor : activeTab.includes(option.title) ? "#FE9900" : "white"}}>
                        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", }}>
                          <Box>{option.title}</Box> <Checkbox checked={activeTab.includes(option.title)} />
                        </Box>
                      </MenuItem>)
                    })
                  }
              </Select>
              <Box sx={{display : "flex", alignItems: "center", columnGap: "20px"}}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "bold", marginRight: "-15px", marginLeft: "5px" }}
              >
                VERSION
            </Typography>              
              <Select
                    labelId="version"
                    id="version"
                    value={version.toString()}
                    label="Version"
                    onChange={(event) => {setVersion(parseInt(event.target.value))}}
                    sx={{ width: "75px", height: "50px", backgroundColor : "white"}}
                >
                    {forecast?.versions.map((version) => (
                      <MenuItem key={version.number} value={version.number} sx={{backgroundColor : version.official ? "#FE9900" : ""}}>
                        {version.number}
                      </MenuItem>
                    ))}
                </Select>
                <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "#FFD28E"}} onClick={duplicateVersion}>Dupliquer</Button>
                <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "#FFD28E" }} onClick={deleteVersion} disabled={official}>Supprimer</Button>
                <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "#FFD28E" }} onClick={changeOfficialVersion} disabled={official}>Version officielle</Button>
              </Box>
            </Toolbar>
          </AppBar>
    )
}

export default ForecastHeader;