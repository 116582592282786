// MaterialUI
import { Card, Box, Typography } from "@mui/material";

// Img
import image from "../../img/hello.webp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { useNavigate } from "react-router";

const Home = () => {
  const menu = [
    {icon : <PriceChangeIcon sx={{width: "100%", height: "100px"}} />, title : "Chiffrage Projets", link : "/chiffrageCDC"},
    {icon : <CalendarMonthIcon sx={{width: "100%", height: "100px"}} />, title : "Chiffrage Prévisionnel", link : "/prévisionnel"}
  ]

  let navigate = useNavigate();

  return (
    <div>
      <Card
        component="main"
        sx={{
          backgroundColor: "white",
          flexGrow: 1,
          p: 2,
          m: 1,
          height: "90vh"
        }}
      >
        <Typography variant="h2" sx={{textAlign: "center"}}>Bienvenue sur Kairos</Typography>

        <Box sx={{textAlign: "center", marginTop: "50px"}}>
          <Box sx={{width: "100%", display: "flex", justifyContent: "space-evenly", margin: "auto"}}>
            {
              menu.map((item) => {
                return(
                  <Box sx={{
                    width: "350px",
                    height: "200px",
                    backgroundColor: "#F2F2F2",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    "&:hover" : {
                      cursor: "pointer",
                      filter: "invert(1)",
                    }
                  }}
                  onClick={()=>{navigate(item.link)}}
                  >
                    {item.icon}
                    <Typography variant="subtitle2" sx={{fontSize: "1.8em"}}>{item.title}</Typography>
                  </Box>
                )
              })
            }
          </Box>
          <Box
            component="img"
            sx={{width: "35%"}}
            alt="image de bienvenue"
            src={image}
          />
        </Box>
      </Card>
    </div>
  );
};

export default Home;
