import axios from "axios";
import { fetchPostProtect } from "../../fetchs/post";
import { IForecastDuplicateValues, IForecastFormValues } from "../../interface/forecast";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import getForecastsData from "./getForecastsData";
import { urlAPI } from "../../config/config";
import { useDispatch } from "react-redux";

export const addForecast = async (
    values : IForecastFormValues,
    token : string,
    setOpenModal : Function,
    dispatch : Function,
    setForecastData : Function,
    setError : Function,
    forecastId? : string
) => {
    const body = {
        startDate : values.startDate,
        lenght : values.lenght,
        name : values.name,
        versions : [{number : "1", official : true}],
    };

    if (token) {
        try {
            const response = await axios.post(
                `${urlAPI}/forecast/add`,
                body,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 201) {
                setOpenModal(false);
                dispatch(showSnackbar(response.data.message, "success"));
                getForecastsData(setForecastData, token, dispatch, setError);
            } else if (response.status === 401) {
                dispatch(sessionEnd());
            }
        } catch (error: any) {
            if (error.response) {
                console.error("Erreur lors de l'ajout du prévisionnel :", error.response.data.error);
                dispatch(showSnackbar(error.response.data.error, 'error'));
                setError(error.response.data.error);
            } else {
                setError("Erreur de connexion au serveur.");
            }
        }
    }
}

export const updateForecast = async (
    values : IForecastFormValues,
    token : string,
    setOpenModal : Function,
    dispatch : Function,
    setForecastData : Function,
    setError : Function,
    forecastId : string
) => {
    const body = JSON.stringify({
        id : forecastId,
        lenght : values.lenght,
        name : values.name,
    });

    if (token) {
        await fetchPostProtect(token, "/forecast/update", body)
        .then((response) => {
            if (response.status === 200) {
                setOpenModal(false)
                dispatch(showSnackbar("Prévisionnel mis à jour avec succès !", "success"));
                getForecastsData(setForecastData, token, dispatch, setError);
            } else if (response.status === 401) {
                dispatch(sessionEnd());
            } else {
                response.json().then((result) => setError(result.message));
            }
        })
        .catch((err) => setError(err));
    }
}

export const duplicateForecast = async (
    values : IForecastDuplicateValues,
    token : string,
    setOpenModal : Function,
    dispatch : Function,
    setForecastData : Function,
    setError : Function
) => {
    console.log("duplication");
    const body = JSON.stringify({
        forecastId : values.forecastId,
        startDate : values.startDate,
        lenght: values.lenght,
        versions : values.versions,
    });

    if (token) {
        await fetchPostProtect(token, "/forecast/duplicate", body)
        .then((response) => {
            if (response.status === 200) {
                setOpenModal(false)
                dispatch(showSnackbar("Prévisionnel dupliqué avec succès !", "success"));
                getForecastsData(setForecastData, token, dispatch, setError);
            } else if (response.status === 401) {
                dispatch(sessionEnd());
            } else {
                response.json().then((result) => setError(result.message));
            }
        })
        .catch((err) => setError(err));
    }
}