import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { IFees, IFeesFormValues } from "../../../interface/fees";
import { useNavigate } from "react-router";
import FeeMonthFields from "./FeeMonthFields";
import FeeInfosFields from "./FeeInfosFields";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useState } from "react";
import { sessionEnd, showSnackbar } from "../../../redux/actions";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import axios from "axios";
import { urlAPI } from "../../../config/config";

const validationSchema = yup.object({
  label: yup.string().trim().min(3, "Le libellé doit contenir au moins 3 caractères").required("Le libellé est obligatoire"),
  agency: yup.string().trim().nullable().notRequired(),
  domain: yup.string().nullable().notRequired(),
  beneficiary: yup.string().nullable().notRequired(),
  months: yup.lazy((obj) =>
    yup.object(
      Object.keys(obj || {}).reduce((acc: any, key: any) => {
        acc[key] = yup
          .number()
          .moreThan(-1, "Les montants doivent être positifs")
          .required("Les montants même de 0 € sont obligatoires.");
        return acc;
      }, {})
    )
  ),
});

const AddUpdateFee = ({
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList,
  initialValue,
  isEdit
}: {
  handleSubmit: Function;
  forecastId?: string;
  version?: number;
  setOpenModal: Function;
  monthList: { name: string; field: string }[];
  initialValue: Partial<IFees> | null;
  isEdit: any;
}) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [error, setError] = useState("");
  
  let initialValues: IFeesFormValues = {
    label: "",
    agency: "",
    domain: "",
    beneficiary: "",
    months: monthList.reduce((acc: any, item: any) => {
      acc[item.field] = 0;
      return acc;
    }, {}),
  }

  if (isEdit && initialValue) {
    initialValues = {
      label: initialValue.label || "",
      agency: initialValue.agency || "",
      domain: initialValue.domain || "",
      _id: initialValue?._id || "",
      beneficiary: initialValue?.beneficiary?._id || "",
      months: monthList.reduce((acc: any, item: any) => {
        acc[item.field] = initialValue.months?.[item.field] || 0;
        return acc;
      }, {}),
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: IFeesFormValues) => {
      if (!token || !forecastId) {
        console.error("Token ou Forecast ID manquant.");
        setError("Token ou Forecast ID manquant. Veuillez réessayer.");
        return;
      }
      handleSubmit(token, values, forecastId, version, setOpenModal, dispatch, setError);
    },
  });


  const handleClose = () => {
    setShowConfirmDialog(true);
  };
  const confirmClose = () => {
    setShowConfirmDialog(false);
    setOpenModal(false);
  };
  const cancelClose = () => {
    setShowConfirmDialog(false);
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        mt: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        <FeeInfosFields formik={formik} />
        <FeeMonthFields formik={formik} monthList={monthList} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>
          Annuler
        </Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          {isEdit ? "Enregistrer la modificaiton" : "Enregistrer"}
        </Button>
      </Box>
      <AlerteDialog
        open={showConfirmDialog}
        handleClose={cancelClose}
        deleteProject={confirmClose}
        title="Confirmer l'abandon"
        contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
        color="error"
      />
    </Box>
  );
};

export default AddUpdateFee;
