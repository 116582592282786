import { Box, Button, Checkbox, Dialog, DialogTitle, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DataGrid, getGridStringOperators, GridColDef, GridRowId, GridRowsProp } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import getFeesData from "../../../tools/forecast/getFeesData";
import { IFees } from "../../../interface/fees";
import { useParams } from "react-router";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import AddUpdateFee from "../AddUpdate/AddUpdateFee";
import CloseIcon from "@mui/icons-material/Close";
import { addFee, handleUpdateFee } from "../../../tools/forecast/addUpdateFees";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { showSnackbar } from "../../../redux/actions";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import getInvestsData from "../../../tools/forecast/getInvestData";
import AddUpdateInves from "../AddUpdate/AddUpdateInvest";
import AddUpdateInvest from "../AddUpdate/AddUpdateInvest";
import { addInvest, handleUpdateInvest } from "../../../tools/forecast/addUpdateInvest";



const Invests = ({ forecastVersion, forecastDates }: { forecastVersion: number | undefined, forecastDates: string }) => {
    let dispatch = useDispatch();

    let token = useSelector((state: RootState) => {
        return state.user.token;
    });

    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const [actionType, setActionType] = useState<"delete" | "close" | null>(null);
    const [confirmationMessage, setConfirmationMessage] = useState<string>("");

    const [error, setError] = useState("");
    const [feesData, setFeesData] = useState<IFees[]>([]);
    const params = useParams();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [selectedFee, setSelectedFee] = useState<IFees | null>(null);
    const [edit, setEdit] = useState<boolean>(false);
    const [agency, setAgency] = useState<string | null>("All");
    const agencies = [
        { label: "Toutes les agences", value: "All" },
        { label: "Agence Nord", value: "Nord" },
        { label: "Agence Sud", value: "Sud" }
    ];
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

    useEffect(() => {
        getInvestsData(params.id, forecastVersion, agency, setFeesData, token, dispatch, setError);
    }, [agency, forecastVersion, openModal]);

    const monthList = getForecastMonthList(forecastDates);

    const columnsToDisplay = {
        infos: [
            { name: "Libellé", field: "label" },
            { name: "Bénéficiaire", field: "beneficiary" },
        ],
        months: monthList
    };

    const columnsInfos: GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            filterable: true,
            minWidth: 200,
            filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
            headerClassName: column.field === "label" ? "sticky-column" : "",
            cellClassName: column.field === "label" ? "sticky-column" : "",
            renderCell: (params) => (
                <div style={{ whiteSpace: "normal" }}>
                    {params.value.split("\n").map((line: any, index: any) => (
                        <span key={index}>
                            {line}
                            <br />
                        </span>
                    ))}
                </div>
            ),
        };
    });

    const columnsMonth: GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
            field: column.field,
            headerName: column.name,
            minWidth: 120,
            headerClassName: "custom-header",
        };
    });

    const columns: GridColDef[] = [
        ...columnsInfos,
        ...columnsMonth,
    ];

    const feesRows: GridRowsProp = feesData.map((fee: IFees) => {

        const beneficiaryName = fee.beneficiary
            ? `${fee.beneficiary.firstname || ""} ${fee.beneficiary.lastname || ""}`.trim()
            : "";

        const agencyInfo = fee.agency ? `Agence ${fee.agency}` : "";

        console.log(fee.domain);
        const domainInfo = fee.domain ? fee.domain : "";

        const beneficiaryContent = [beneficiaryName, agencyInfo, domainInfo]
            .filter((info) => info.length > 0)
            .join("\n");

        const feesRow = {
            id: fee._id,
            label: fee.label,
            beneficiary: beneficiaryContent || "",
        };

        const monthExpenses = Object.fromEntries(
            Object.entries(fee.months).map(([key, value]) => [key, `${value} €`])
        );

        return { ...feesRow, ...monthExpenses };
    });

    const totalByMonth = monthList.reduce((acc: any, item: any) => {
        acc[item.field] = feesData.reduce((sum, fee) => sum + (fee.months[item.field] || 0), 0);
        return acc;
    }, {});

    const totalRow = {
        id: "total",
        label: "",
        beneficiary: " \nTOTAL\n ",
        ...Object.fromEntries(
            Object.entries(totalByMonth).map(([key, value]) => [key, `${value} €`])
        )
    };

    const rows: GridRowsProp = feesData.length > 0 ? [...feesRows, totalRow] : [...feesRows];

    const handleClose = () => {
        setActionType("close");
        setShowConfirmDialog(true)
    };
    const confirmClose = () => {
        setShowConfirmDialog(false);
        setActionType(null);
        setOpenModal(false);
    }

    const cancelClose = () => {
        setShowConfirmDialog(false);
        setActionType(null);
    };

    const handleOpenAddFeeModal = () => {
        setEdit(false);
        setSelectedFee(null);
        setOpenModal(true);
    };

    const handleOpenEditFeeModal = (fee: any) => {
        setEdit(true);
        setSelectedFee(fee);
        setOpenModal(true);
    };

    const handleDeleteConfirmation = () => {
        if (selectedRows.length === 0) {
            dispatch(showSnackbar("Aucun frais sélectionné pour suppression.", "error"));
            return;
        }

        const message =
            selectedRows.length === 1
                ? "Êtes-vous sûr de vouloir supprimer ce frais ?"
                : `Êtes-vous sûr de vouloir supprimer ces ${selectedRows.length} frais ?`;

        setConfirmationMessage(message);
        setActionType("delete");
        setShowConfirmDialog(true);
    };

    const confirmDelete = async () => {
        setShowConfirmDialog(false);

        try {
            const response = await axios.delete(`${urlAPI}/invest/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                data: { selectedId: selectedRows },
            });

            if (response.data.message === "successfully") {
                dispatch(showSnackbar("Frais supprimé avec succès.", "success"));
                setFeesData((prev) => prev.filter((fee) => !selectedRows.includes(fee._id)));
                setSelectedRows([]);
            }
        } catch (error) {
            console.error("Erreur lors de la suppression :", error);
            dispatch(showSnackbar("Erreur lors de la suppression des frais.", "error"));
        }
    };

    const handleDuplication = async () => {
        if (selectedRows.length === 0) {
            dispatch(showSnackbar("Aucun frais sélectionné pour une duplication.", "error"));
            return;
        }
        if (selectedRows.length > 1) {
            dispatch(showSnackbar("Vous ne pouvez pas dupliquer plus d'un frais en même temps.", "error"));
            return;
        }

        try {
            const response = await axios.post(`${urlAPI}/invest/duplicate/${selectedRows[0]}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.message === "successfully") {
                dispatch(showSnackbar("Frais duppliqué avec succès.", "success"));
                const newFee = response.data.data;
                setFeesData((prevFees) => [...prevFees, newFee]);
                setSelectedRows([]);
            }
        } catch (error) {
            console.error("Erreur lors de la duplication :", error);
            dispatch(showSnackbar("Erreur lors de la duplication d'un frais.", "error"));
        }
    };

    const handleSubmit = edit ? handleUpdateInvest : addInvest;

    return (
        <>
            <Typography variant="h3" sx={{ textAlign: "center", backgroundColor: "#FFD28E" }}>Investissements</Typography>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
                <Select
                    labelId="agency"
                    id="agency"
                    value={agency}
                    label="Agence"
                    onChange={(event) => { setAgency(event.target.value) }}
                    sx={{ width: "200px", height: "50px" }}
                >
                    {agencies.map((opt, index) => (
                        <MenuItem key={index} value={opt.value}>
                            {opt.label}
                        </MenuItem>
                    ))}
                </Select>
                <Box sx={{ display: "flex", gap: 2, mt: 3, mb: 2 }}>
                    {selectedRows.length > 0 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "red" }} onClick={handleDeleteConfirmation}>- Supprimer</Button>
                    )}
                    {selectedRows.length === 1 && (
                        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleDuplication}>Dupliquer un frais</Button>
                    )}

                    <Button
                        type="button"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleOpenAddFeeModal}
                    >
                        + Ajouter un frais
                    </Button>
                </Box>
            </Box>
            <DataGrid
                disableRowSelectionOnClick={true}
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                checkboxSelection
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={(selectionModel) => {
                    setSelectedRows(selectionModel);
                }}
                isRowSelectable={(params) => {
                    return params.row.id !== 'total';
                }}
                onCellClick={(params) => {
                    const allowedFields = ["label", "beneficiary", ...monthList.map((m) => m.field)];
                    if (allowedFields.includes(params.field)) {
                        const rowId = params.row.id;
                        if (rowId) {
                            const rowData = feesData.find((fee) => fee._id === rowId);

                            if (rowData) {

                                handleOpenEditFeeModal(rowData);

                            } else {
                                console.error("Aucune donnée trouvée pour cet ID.");
                            }
                        }
                    }
                }}


                sx={{
                    "& .MuiDataGrid-columnHeader .sticky-column": {
                        position: "sticky",
                        top: 0,
                        zIndex: 10,
                        backgroundColor: "white",
                    },
                    "& .sticky-column": {
                        position: "sticky",
                        left: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                    },
                    height: "100%",
                }}
            />


            <Dialog
                open={openModal}
                keepMounted={false}
                onClose={(event: {}, reason: "backdropClick" | "escapeKeyDown") => {
                    if (reason === "backdropClick" || reason === "escapeKeyDown") {
                        return;
                    }
                    setOpenModal(false);
                }}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth={true}
                maxWidth="xl"
                scroll="paper"
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle>
                        {edit ? "Modifier un frais" : "Ajouter un frais"}
                    </DialogTitle>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <AddUpdateInvest
                    handleSubmit={handleSubmit}
                    forecastId={params.id}
                    version={forecastVersion}
                    setOpenModal={setOpenModal}
                    monthList={monthList}
                    initialValue={selectedFee || {}}
                    isEdit={edit}
                />
            </Dialog>

            <AlerteDialog
                open={showConfirmDialog}
                handleClose={cancelClose}
                deleteProject={actionType === "delete" ? confirmDelete : confirmClose}
                title={actionType === "delete" ? "Confirmer la suppression" : "Confirmer l'abandon"}
                contentText={
                    actionType === "delete"
                        ? confirmationMessage
                        : "Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
                }
                color="error"
            />

        </>
    );
};

export default Invests;