import { Alert, Autocomplete, Button, CircularProgress, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { IFees } from "../../../interface/fees";
import { colorOrange } from "../../../config/color";
import { useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";

const InvestInfosFields = ({
    fee,
    formik,
}: {
    fee?: IFees;
    formik: any;
}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.user.token);

    const [message, setMessage] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [salaries, setSalaries] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch list of beneficiaries (salaries)
    const fetchSalaries = async () => {
        try {
            const response = await axios.get(`${urlAPI}/salary`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            const salaries = response.data.data;
            setSalaries(
                salaries
                    .filter((salary: any) => salary.status !== 0)
                    .sort((a: any, b: any) => a.firstname.localeCompare(b.firstname))
            );
        } catch (error: any) {
            console.error(error);
            setError("Erreur lors de la récupération des salariés");
            setTimeout(() => setError(""), 4000);
        }
    };

    useEffect(() => {
        fetchSalaries();
    }, []);

    const selectInputs = [
        {
            id: "agency",
            options: ["Nord", "Sud"],
            defaultValue: fee?.agency || "",
            label: "Agence",
            touched: formik.touched.agency,
            errors: formik.errors.agency,
            required: true,
        },
        {
            id: "domain",
            options: ["PC", "Serveurs ", "Infra", "Travaux - CDC", "Autres"],
            defaultValue: fee?.domain || "",
            label: "Domaine",
            touched: formik.touched.domain,
            errors: formik.errors.domain,
            required: true,
        },
    ];

    const handleFetch = async () => {
        setMessage("");
        setError("");
        setLoading(true);
        try {
            const syncResponse = await axios.get(`${urlAPI}/salary/dolibarr`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setMessage(syncResponse.data.message);
            setTimeout(() => setMessage(""), 4000);

            if (syncResponse.status === 200) {
                await fetchSalaries();
            }
        } catch (error: any) {
            const errorMessage =
                error.message ||
                error.response?.data?.message ||
                "Erreur lors de la mise à jour des salariés.";
            setError(errorMessage);
            setTimeout(() => setError(""), 4000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "30%",
            }}
        >
            <TextField
                margin="normal"
                required
                type="text"
                id="label"
                label="Libellé du frais"
                name="label"
                autoComplete="label"
                value={formik.values.label}
                onChange={formik.handleChange}
                error={formik.touched.label && Boolean(formik.errors.label)}
                helperText={formik.touched.label && formik.errors.label}
                autoFocus
            />




            {selectInputs.map((select) => {
                return (
                    <Autocomplete
                        key={select.id}
                        id={select.id}
                        options={select.options}
                        value={formik.values[select.id] || null}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(event, value) => formik.setFieldValue(select.id, value || "")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={select.id}
                                margin="normal"
                                label={select.label}
                                error={select.touched && Boolean(select.errors)}
                                helperText={select.touched && select.errors}
                            />
                        )}
                    />
                );
            })}



            {/* Error and Success Messages */}
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            
        </Box>
    );
};

export default InvestInfosFields;
