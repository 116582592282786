import axios from "axios";
import { urlAPI } from "../../config/config";
import { showSnackbar } from "../../redux/actions";
import { ISavingFormValues } from "../../interface/saving";

export const addUpdateSaving = async (
    token: string,
    values: ISavingFormValues,
    forecastId: string,
    version: number,
    setOpenModal: Function,
    dispatch: Function,
    setError: Function,
    savingId? : string
) => {
    try {
        if(!savingId){
          const response = await axios.post(`${urlAPI}/forecast/saving/add`,
            { ...values, forecastId, forecastVersion: version },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } 
        else{
          const response = await axios.post(`${urlAPI}/forecast/saving/update/${savingId}`,
            { ...values, forecastId, forecastVersion: version },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        }       
        setOpenModal(false);
        dispatch(showSnackbar("Epargne enregistrée avec succès.", "success"));
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        setError("Impossible d'enregistrer l'épargne'. Veuillez réessayer.");
        dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
      }
};
