import { DataGrid, getGridStringOperators, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import getForecastMonthList from "../../../tools/forecast/getForecastMonthList";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { ITurnover } from "../../../interface/turnover";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import getTurnoverData from "../../../tools/forecast/getTurnoverData";
import { useParams } from "react-router";

const TurnoverData = ({ 
    forecastVersion, 
    forecastDates, 
    type,
    agency,
    setSelectedTurnover,
    openModal,
    showDeleteDialog
} : { 
    forecastVersion: number | undefined, 
    forecastDates: string , 
    type : string,
    agency : string | null,
    setSelectedTurnover: Function,
    openModal: boolean,
    showDeleteDialog : boolean
}) => {
    let dispatch = useDispatch();
    
    let token = useSelector((state: RootState) => {
        return state.user.token;
    });
    const [error, setError] = useState("");
    const params = useParams();
    
    const [turnoverData, setTurnoverData] = useState<ITurnover[]>([]);
    const monthList = getForecastMonthList(forecastDates);

    useEffect(() => {
        getTurnoverData(params.id, forecastVersion, agency, type, setTurnoverData, token, dispatch, setError);
    }, [agency, forecastVersion, openModal, showDeleteDialog]);

    const handleRowSelectionChange = (newSelection: any) => {
        const newSelectedTurnover = { [type] : newSelection};
        setSelectedTurnover((prevSelectedTurnover : any) => 
            Object.assign({}, prevSelectedTurnover, newSelectedTurnover)
        );
    };
    
    const columnsToDisplay = {
        infos : [ 
            {name : "Agence", field : "agency"}, 
            {name : "Client", field : "client"}, 
            {name : type === "Régie" ? "Collaborateur" : "Projet", field : type === "Régie" ? "collab" : "project"}
        ],
        months : monthList
    }
    
    const columnsInfos : GridColDef[] = columnsToDisplay.infos.map((column) => {
        return {
                field: column.field,
                headerName: column.name,
                filterable: true,
                minWidth : 200,
                filterOperators: getGridStringOperators().filter((operator) => operator.value === "contains"),
                headerClassName: "custom-header",
                renderCell: (params) => (
                    <div style={{ whiteSpace: 'normal' }}>
                        {params.value?.split('\n').map((line: any, index: any) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                ),
        }
    })

    const columnsMonth : GridColDef[] = columnsToDisplay.months.map((column) => {
        return {
                field: column.field,
                headerName: column.name,
                minWidth : 160,
                headerClassName: "custom-header",
                renderCell: (params) => (
                    <div style={{ whiteSpace: 'normal' }}>
                        {params.value.split('\n').map((line: any, index: any) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </div>
                ),
        }
    })

    const columns: GridColDef[] = [...columnsInfos, ...columnsMonth];

    const turnoverRows: GridRowsProp = turnoverData.map((turnover: ITurnover) => {
        const turnoverRow : any = {
            id: turnover._id,
            agency: turnover.agency ? turnover.agency : "Non précisée",
            client: turnover.client.name,
        };       

        let monthExpenses;
        if(turnover.type === "Régie") { 
            turnoverRow["collab"] = turnover.collab ? turnover.collab.firstname + " " + turnover.collab.lastname : turnover.fictifCollab;
            monthExpenses = Object.fromEntries(
                Object.entries(turnover.months).map(([key, value]) => [value.label, `Nb JO : ${value.openDays}\nTJM : ${value.tjm} €/JO\nTotal : ${value.openDays * value.tjm} €`])
            );
        }
        else{
            turnoverRow["project"] = turnover.project ? turnover.project : "Non précisé";
            monthExpenses = Object.fromEntries(
                Object.entries(turnover.months).map(([key, value]) => [value.label, `\n${value.amount} €\n`])
            );
        }
        return { ...turnoverRow, ...monthExpenses };
    });

    const totalByMonth = monthList.reduce((acc : any, item : any) => {
        acc[item.field] = turnoverData.reduce((sum: number, turnover: any) => {
            const matchingMonth = turnover.months.find((month: any) => month.label === item.field);
            if (matchingMonth) {
                if(turnover.type === "Régie"){
                    return sum + (matchingMonth.openDays * matchingMonth.tjm || 0);
                }
                else{
                    return sum + (matchingMonth.amount || 0);
                }
            }
            return sum;
        }, 0);
        return acc;
    }, {})

    const totalRow = {
        id: "total", // Une ID unique pour cette ligne
        collab: `\nTOTAL ${type.toUpperCase()}\n`,
        project : `\nTOTAL ${type.toUpperCase()}\n`,
        ...Object.fromEntries(
            Object.entries(totalByMonth).map(([key, value]) => [key, `${value} €`])
        )
    };

    const rows: GridRowsProp = turnoverData.length > 0 ? [...turnoverRows, totalRow] : [...turnoverRows];

    if(turnoverData.length > 0){
        return (
            <>
                <Typography variant="h5" sx={{textAlign: "center", backgroundColor: "black", color: "white"}}>{type}</Typography>
                <DataGrid
                    disableRowSelectionOnClick={true}
                    rows={rows}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    onCellClick={(row) => {}}
                    sx={{
                        '& .MuiDataGrid-columnHeader .sticky-column' : {
                            position: "sticky",
                            top: 0,
                            zIndex: 10,
                            backgroundColor: "white",
                        },
                        '& .sticky-column': {
                            position: 'sticky',
                            left: 0,
                            zIndex: 1,
                            backgroundColor: 'white',
                        },
                        height: "100%"
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={handleRowSelectionChange}
                    isRowSelectable={(params) => {
                        return params.row.id !== 'total';
                    }}
                    
                />
            </>
        )
    }
    else{
        return <></>
    }
}

export default TurnoverData;