import { Switch, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react";

const TurnoverMonthFields = ({
    formik,
    monthList,
    tjm
} : {
    formik : any,
    monthList : {name : string, field : string}[],
    tjm : number
}) => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const [typeChecked, setTypeChecked] = useState<boolean>(false);
    const [fixedTjm, setFixedTjm] = useState<boolean>(false);

    const [amount, setAmount] = useState<number>(0);
    const [tjmAmount, setTjmAmount] = useState<number>(tjm);

    useEffect(() => {
        monthList.map((key, i) => {
            formik.values.months[i].tjm = tjm ?  tjm : 0;
        })
        setTjmAmount(tjm)
    }, [formik.values.collab])

    const handleFixedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = event.target.name;
        inputName === "tjm" ? setTjmAmount(parseInt(event.target.value)) : setAmount(parseInt(event.target.value));
        monthList.map((key, i) => {
            formik.values.months[i][inputName] = parseInt(event.target.value);
        })
    };

    const handleChange = (e : any) => {
        const { name, value } = e.target;
        const index = name.split("-")[1];
        const field = name.split("-")[2];
        formik.setFieldValue(`months.${index}.${field}`, value === "" ? "" : Number(value));
    };

    const handleSwitchChange = () => {
        if(formik.values.type === "Régie"){
            setFixedTjm(!fixedTjm)
            if(fixedTjm){
                monthList.map((key, i) => {
                    formik.values.months[i].tjm = tjmAmount;
                })
            }
        }
        else{
            setTypeChecked(!typeChecked)
            if(typeChecked){
                monthList.map((key, i) => {
                    formik.values.months[i].amount = amount;
                })
            }
        }
    }

    return (
        <Box sx={{display : "flex", flexDirection: "column", justifyContent: "flex-start", width: "60%"}}>
            <Box sx={{display : "flex", justifyContent: "center"}}>
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : (formik.values.type === "Régie" ? !fixedTjm : !typeChecked) ? "bold" : ""}}>
                    {formik.values.type === "Régie" ? "TJM" : "Montant"} constant
                </Typography>
                <Switch 
                    {...label} 
                    onClick={handleSwitchChange} 
                    checked={(formik.values.type === "Régie" ? fixedTjm : typeChecked)} 
                /> 
                <Typography variant="h6" sx={{textAlign: "center", fontWeight : (formik.values.type === "Régie" ? fixedTjm : typeChecked) ? "bold" : ""}}>
                    {formik.values.type === "Régie" ? "TJM" : "Montant"} variable
                </Typography>
            </Box>
            {(formik.values.type === "Régie" ? !fixedTjm : !typeChecked) && (
                <TextField
                    margin="normal"
                    required
                    type="number"
                    id={formik.values.type === "Régie" ? "tjm" : "amount"}
                    label={formik.values.type === "Régie" ? "TJM Mensuel" : "Montant Mensuel"}
                    name={formik.values.type === "Régie" ? "tjm" : "amount"}
                    autoComplete={formik.values.type === "Régie" ? "tjm" : "amount"}
                    value={formik.values.type === "Régie" ? tjmAmount : amount}
                    onChange={handleFixedChange}
                    autoFocus
                    sx={{width: "40%", marginInline: "auto"}}
                />
            )}
            <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "left", columnGap: "30px"}}>
            {
                monthList.map((month, i) => {
                    if(formik.values.type === "Régie"){
                        return (
                            <Box sx={{display : "flex", flexDirection : "column", justifyContent : "center", width : "30%"}}>
                                <Typography variant="subtitle1" sx={{textAlign: "center"}}>{month.name}</Typography>
                                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                                    <TextField
                                        margin="normal"
                                        required
                                        type="number"
                                        id={`month-${i}-openDays`}
                                        label={"Jours ouvrés"}
                                        name={`month-${i}-openDays`}
                                        autoComplete={`month-${i}-openDays`}
                                        value={formik.values.months[i].openDays}
                                        onChange={handleChange}
                                        autoFocus
                                        sx={{width: "45%"}}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        type="number"
                                        id={`month-${i}-tjm`}
                                        label={"TJM"}
                                        name={`month-${i}-tjm`}
                                        autoComplete={`month-${i}-tjm`}
                                        value={formik.values.months[i].tjm}
                                        onChange={handleChange}
                                        autoFocus
                                        disabled={!fixedTjm}
                                        sx={{width: "45%"}}
                                    />
                                </Box>
                            </Box>
                        )
                    }
                    else{
                        return (
                            <TextField
                                margin="normal"
                                required
                                type="number"
                                id={`month-${i}-amount`}
                                label={month.name}
                                name={`month-${i}-amount`}
                                autoComplete={`month-${i}-amount`}
                                value={formik.values.months[i].amount}
                                onChange={handleChange}
                                autoFocus
                                disabled={!typeChecked}
                                sx={{width: "30%"}}
                            />
                        )
                    }
                })
            }
            </Box>
        </Box>
    )
}

export default TurnoverMonthFields;