import * as React from "react";

// MaterialUI
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";

// Component
import DrawerHeader from "./DrawerHeader";

// Icons
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

//Logo
import logo from "../img/logo/Kairos-removebg-preview.webp";

// React router dom
import { Outlet, useNavigate } from "react-router-dom";

// Redux
import { sessionEnd } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/index";

// Tools
import useWindowDimensions from "../tools/useWindowDimensions";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export default function LayoutForecast() {
  const dispatch = useDispatch();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [locked, setLocked] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  let navigate = useNavigate();

  const { width } = useWindowDimensions();
  const user = useSelector((state: RootState) => state.user.username);

  /*
  En utilisant le hook useWindowDimensions, on peut récupérer la hauteur et la largeur de la fenêtre
  On déclare un bool permettant de definir si la fenêtre est en mode mobile 
  */
  let mobile: boolean = false;
  if (width) {
    mobile = width > 900 ? false : true;
  }

  const drawerWidth = 280;

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleDrawerOpen = () => {
    if (!locked) {
      setOpen(true);
      setIsVisible(true);
    }
  };

  const appBarContext = (
    <Toolbar>
      <div className="flex-container-navbar">
        <div className="flex-container-left">
          {mobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: { xs: "10px", md: "36px" },
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box
            component="img"
            sx={{
              height: 48,
              width: 37,
              cursor: "pointer",
            }}
            alt="logo Kairos"
            src={logo}
            className="logo-kairos"
            onClick={() => navigate("/")}
          />
        </div>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" noWrap component="div" sx={{ flexGrow: 1 }}>
            {user}
          </Typography>
          <IconButton
            edge="end"
            sx={{
              color: "red",
              marginLeft: { xs: "10px", md: "20px" },
            }}
            onClick={() => dispatch(sessionEnd())}
            aria-label="fin de session"
          >
            <PowerSettingsNewIcon />
          </IconButton>
        </Box>
      </div>
    </Toolbar>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {mobile ? (
        <>
          <MuiAppBar position="fixed" color="secondary">
            {appBarContext}
          </MuiAppBar>
        </>
      ) : (
        <>
          <AppBar position="fixed" color="secondary">
            {appBarContext}
          </AppBar>
        </>
      )}
      {/*//! Extrenaliser ce composant box et son style ??? */}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        {/* Outlet permet de remplacer children pour une route */}
        <Outlet />
      </Box>
    </Box>
  );
}
