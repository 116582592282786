import axios from "axios";
import { urlAPI } from "../../config/config";
import { fetchPostProtect } from "../../fetchs/post";
import { IFees, IFeesFormValues } from "../../interface/fees";
import { sessionEnd, showSnackbar } from "../../redux/actions";
import { fetchPutProtect } from "../../fetchs/put";

export const addFee = async (
    token: string,
    values: IFeesFormValues,
    forecastId: string,
    version: number,
    setOpenModal: Function,
    dispatch: Function,
    setError: Function
) => {
    try {
        const cleanedValues = {
            ...values,
            label: values.label.trim(), 
        };
        const response = await axios.post(`${urlAPI}/fee/`,
          { ...cleanedValues, forecastId, forecastVersion: version },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );        
        setOpenModal(false);
        dispatch(showSnackbar("Frais enregistré avec succès.", "success"));
      } catch (error: any) {
        if (error.response) {
          console.error("Erreur API :", error.response.data);
          console.error("Statut :", error.response.status);
        } else if (error.request) {
          console.error("Erreur de requête :", error.request);
        } else {
          console.error("Erreur :", error.message);
        }
  
        setError("Impossible d'enregistrer le frais. Veuillez réessayer.");
        dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
      }
};

export const handleUpdateFee = async (
    token: string,
    values: IFeesFormValues,
    forecastId: string,
    version: number,
    setOpenModal: Function,
    dispatch: Function,
    setError: Function
) => {
  try{
    const cleanedValues = {
        ...values,
        label: values.label.trim(), 
    };
    const response = await axios.put(`${urlAPI}/fee/`,
      { ...cleanedValues, forecastId, forecastVersion: version },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );     setOpenModal(false);
    dispatch(showSnackbar("Frais enregistré avec succès.", "success"));
  } catch (error: any) {
    if (error.response) {
      console.error("Erreur API :", error.response.data);
      console.error("Statut :", error.response.status);
    } else if (error.request) {
      console.error("Erreur de requête :", error.request);
    } else {
      console.error("Erreur :", error.message);
    }

    setError("Impossible d'enregistrer le frais. Veuillez réessayer.");
    dispatch(showSnackbar("Erreur lors de l'enregistrement.", "error"));
  }
};
