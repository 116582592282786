import { Alert, Autocomplete, Button, CircularProgress, Switch, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colorOrange } from "../../../config/color";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { ITurnover } from "../../../interface/turnover";
import { sessionEnd } from "../../../redux/actions";
import { IClient } from "../../../interface/client";
import { getAll } from "../../../fetchs/get";
import TurnoverCollabField from "./TurnoverCollabField";

const TurnoverInfosFields = ({
  turnover,
  formik,
  setTjm
}: {
  turnover?: ITurnover,
  formik: any,
  setTjm : Function
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.user.token);

  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [salaries, setSalaries] = useState<any>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  
  const [loading, setLoading] = useState<boolean>(false);

  const getAllClients = useCallback(() => {
    if (token) {
      getAll(token, "/clients/", setClients, () => dispatch(sessionEnd()), setError);
    }
  }, []);

  const clientActeam = clients.map((client: IClient) => {
    return { _id: client._id, name: client.name };
  });

  useEffect(() => {
    getAllClients();
  }, [getAllClients]);

  const fetchSalaries = async () => {
    try {
      const response = await axios.get(`${urlAPI}/salary`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setSalaries(response.data.data);
    } catch (error: any) {
      setError("Erreur lors de la récupération des salariés");
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    fetchSalaries();
  }, [])


  const selectInputs = [
    { id: "type", options: ["Forfait", "Hébergement", "Régie", "TMA/TME", "TRA"], defaultValue: turnover?.type, label: "Type de prestation", touched: formik.touched.type, errors: formik.errors.type, required: true },
    { id: "agency", options: ["Nord", "Sud"], defaultValue: turnover?.agency, label: "Agence", touched: formik.touched.agency, errors: formik.errors.agency, required: false },
    { id: "client", options: clientActeam, defaultValue: turnover?.client, label: "Client", touched: formik.touched.client, errors: formik.errors.client, required: true },
  ]

  if(formik.values.type === "Régie") {
    selectInputs.push(
        { id: "collab", options: salaries, defaultValue: "", label: "Collaborateur réel", touched: "", errors: "", required: false}
    )
  }

  const handleFetch = async () => {
    setMessage("");
    setError("");
    setLoading(true);
    try {
      const syncResponse = await axios.get(`${urlAPI}/salary/dolibarr`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setMessage(syncResponse.data.message);
      setTimeout(() => setMessage(""), 4000);

      if (syncResponse.status === 200) {
        await fetchSalaries();
      }
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "30%" }}>
        {
            selectInputs.map((select) => {
              if(select.id === "collab"){
                return <TurnoverCollabField formik={formik} salaries={select.options} turnover={turnover} setTjm={setTjm} />
              }
              else{
                return (
                  <Autocomplete
                      key={select.id}
                      id={select.id}
                      options={select.options}
                      getOptionLabel={(option: any) =>
                        select.id === "client"
                          ? `${option.name || ""}`.trim()
                          : option 
                      }
                      isOptionEqualToValue={(option: any, value: any) =>
                          select.id === "client"
                          ? option._id === value?._id
                          : option === value
                      }
                      renderOption={(props, option: any) =>
                          select.id === "client" ? (
                              <li {...props} data-id_dolibarr={option._id} key={option._id}>
                                  {`${option.name || ""} `.trim()}
                              </li>
                          ) : (
                          <li {...props} key={option}>
                              {option}
                          </li>
                          )
                      }

                      defaultValue={turnover ? select.defaultValue : null}
                      onChange={(event, value: any) => {
                          formik.setFieldValue(
                            select.id,
                            value ? (select.id === "client" ? value._id : value) : ""
                          );
                      }}
                      renderInput={(params) => (
                          <TextField
                          {...params}
                          required={select.required}
                          name={select.id}
                          margin="normal"
                          label={select.label}
                          error={select.touched && Boolean(select.errors)}
                          helperText={select.touched && select.errors}
                          />
                      )}
                    />
                )
              }
            })
        }
        {formik.values.type && formik.values.type !== "Régie" && (
            <TextField
                margin="normal"
                required
                type="text"
                id="project"
                label="Projet associé"
                name="project"
                autoComplete="project"
                value={formik.values.project}
                onChange={formik.handleChange}
                error={formik.touched.project && Boolean(formik.errors.project)}
                helperText={formik.touched.project && formik.errors.project}
                autoFocus
            />
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
        {loading ? (
            <CircularProgress sx={{ margin: "16px auto" }} />
        ) : (
            <>
                <Button
                    onClick={handleFetch}
                    fullWidth
                    sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
                >
                    {"Mettre à jour la liste des clients"}
                </Button>
                {formik.values.type === "Régie" && 
                    <Button
                    onClick={handleFetch}
                    fullWidth
                    sx={{ mt: 3, mb: 2, border: `1px solid ${colorOrange}` }}
                    >
                        {"Mettre à jour la liste des collaborateurs"}
                    </Button>
                
                }
            </>
        )}
        </Box>
    </>
  )
}

export default TurnoverInfosFields;