import { eachDayOfInterval, isWeekend } from "date-fns";

export default function getBusinessDaysForMonth(year : number, month : string) {
    const months = {
        "Janvier" : 0,
        "Février" : 1,
        "Mars" : 2,
        "Avril" : 3,
        "Mai" : 4,
        "Juin" : 5,
        "Juillet" : 6,
        "Août" : 7,
        "Septembre" : 8,
        "Octobre" : 9,
        "Novembre" : 10,
        "Décembre" : 11
    }

    const startDate = new Date(year, months[month as keyof typeof months], 1);
    const endDate = new Date(year, months[month as keyof typeof months] + 1, 0);
  
    const allDays = eachDayOfInterval({
      start: startDate,
      end: endDate,
    });

    const holidays = getFrenchHolidays(year);
  
    return allDays.filter((date) => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        const formattedDate = newDate.toISOString().split("T")[0];
        return !isWeekend(date) && !holidays.includes(formattedDate);
    }).length;
}

function getFrenchHolidays(year : number) {
    const holidays = [
        year.toString()+"-01-01",
        year.toString()+"-05-01",
        year.toString()+"-05-08",
        year.toString()+"-07-14",
        year.toString()+"-08-15",
        year.toString()+"-11-01",
        year.toString()+"-11-11",
        year.toString()+"-12-25",
    ];

    const easterDate = calculateEasterDate(year);

    const easterMonday = new Date(easterDate.getTime() + 1 * 86400000);
    easterMonday.setDate(easterMonday.getDate() + 1);
    const ascendDay = new Date(easterDate.getTime() + 39 * 86400000);
    ascendDay.setDate(ascendDay.getDate() + 1);
    const pentecoteMonday = new Date(easterDate.getTime() + 50 * 86400000);
    pentecoteMonday.setDate(pentecoteMonday.getDate() + 1);
    holidays.push(easterMonday.toISOString().split("T")[0]);
    holidays.push(ascendDay.toISOString().split("T")[0]);
    holidays.push(pentecoteMonday.toISOString().split("T")[0]);

    return holidays;
}


function calculateEasterDate(year : number) {
    const f = Math.floor,
      G = year % 19,
      C = f(year / 100),
      H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
      I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
      J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
      L = I - J,
      month = 3 + f((L + 40) / 44),
      day = L + 28 - 31 * f(month / 4);
    return new Date(year, month - 1, day);
}
