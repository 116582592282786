import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { useEffect, useState } from "react";
import AlerteDialog from "../../AlerteDialog/AlerteDialog";
import { ITurnover, ITurnoverFormValues } from "../../../interface/turnover";
import TurnoverInfosFields from "./TurnoverInfosFields";
import TurnoverMonthFields from "./TurnoverMonthFields";
import getBusinessDaysForMonth from "../../../tools/getBusinessDays";
import axios from "axios";
import { urlAPI } from "../../../config/config";
import TurnoverFixedFields from "./TurnoverFixedFields";

const validationSchema = yup.object({
  agency: yup.string().trim(),
  collab: yup.string(),
  project: yup.string(),
  client: yup.string().required("Le client est obligatoire"),
  type: yup.string().required("Le type de prestation est obligatoire"),
});

const AddUpdateTurnover = ({
  turnoverId,
  handleSubmit,
  forecastId,
  version,
  setOpenModal,
  monthList
} : {
  turnoverId?: string;
  handleSubmit: Function;
  forecastId?: string,
  version?: number,
  setOpenModal: Function,
  monthList: { name: string, field: string }[]
}) => {
  let dispatch = useDispatch();

  let token = useSelector((state: RootState) => state.user.token) as string;

  const [turnover, setTurnover] = useState<ITurnover>();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [tjm, setTjm] = useState<number>(0);

  const getTurnover = async () => {
    if (!token || !turnoverId) {
      return;
    }
    try {
      const syncResponse = await axios.get(`${urlAPI}/forecast/turnover/${turnoverId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setTurnover(syncResponse.data.message);
    } catch (error: any) {
      const errorMessage = error.message || error.response?.data?.message || "Erreur lors de la mise à jour des salariés.";
      setError(errorMessage);
      setTimeout(() => setError(""), 4000);
    }
  }

  useEffect(() => {
    getTurnover();
  }, []);  

  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
        agency: turnover ? turnover.agency : "",
        collab: turnover && turnover.collab ? turnover.collab.firstname + " " + turnover.collab.lastname : "",
        fictifCollab : turnover && turnover.fictifCollab ? turnover.fictifCollab : "",
        project: turnover ? turnover.project : "",
        client: turnover ? turnover.client.name : "",
        type: turnover ? turnover.type : "",
        months : turnover ? turnover.months
        : monthList.map((item: any) => ({
          label: item.field,
          openDays: getBusinessDaysForMonth(item.name.split(" ")[1], item.name.split(" ")[0]),
          tjm: 0,
          amount: 0,
        })),
    },
    validationSchema: validationSchema,
    onSubmit: (values: ITurnoverFormValues) => {
      handleSubmit(token, values, forecastId, version!, setOpenModal, dispatch, setError, turnoverId);
    },
    enableReinitialize: true,
  });

  const handleClose = () => {
    setShowConfirmDialog(true);
  }
  const confirmClose = () => {
    setShowConfirmDialog(false);
    setOpenModal(false);
  }
  const cancelClose = () => {
    setShowConfirmDialog(false);
  }

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 1, width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "600px" }}>
        {!turnover ? (
          <TurnoverInfosFields turnover={turnover} formik={formik} setTjm={setTjm} />
        ) : (
          <TurnoverFixedFields formik={formik} />
        )}
        <TurnoverMonthFields formik={formik} monthList={monthList} tjm={tjm} />
      </Box>
      <Box sx={{ display: "flex", columnGap: "200px" }}>
        <Button type="button" variant="contained" sx={{ mt: 3, mb: 2 }} onClick={handleClose}>Annuler</Button>
        <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>Enregistrer</Button>
      </Box>
      <AlerteDialog
        open={showConfirmDialog}
        handleClose={cancelClose}
        deleteProject={confirmClose}
        title="Confirmer l'abandon"
        contentText="Êtes-vous sûr de vouloir abandonner les modifications sans les enregistrer ?"
        color="error" />
    </Box>
  )
}

export default AddUpdateTurnover;